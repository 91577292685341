export default {
  state: {
    buffs: [],
  },

  mutations: {
    SET_BUFFS(state, buffs) {
      state.buffs = buffs;
    },
  },

  actions: {
    async getBuffs({
      commit
    }) {
      try {
        // eslint-disable-next-line no-undef
        const response = await axios.get('/adminpanel/getbuffs');
        commit("SET_BUFFS", response.data.buffs);
      } catch (error) {
        console.error(error);
        this.$notifyError(error);
      }
    },

    async setBuff(_, {
      buffId,
      name,
      description
    }) {
      try {
        // eslint-disable-next-line no-undef
        await axios.post('/adminpanel/setbuff', {
          buffId,
          name,
          description
        });
        this.$notifySuccess("Бафф изменен")
      } catch (error) {
        console.error(error);
        this.$notifyError(error);
      }
    },
  },

  getters: {
    buffsById: (state) => state.buffs.reduce((buffs, buff) => {
      buffs[buff.buffId] = buff;
      return buffs;
    }, {}),
  },

  namespaced: true
}
