import Vue from "vue";
import Vuex from "vuex";

import buffs from "./modules/buffs";
import guild from "./modules/guild";
import items from "./modules/items";
import leagueRewards from "./modules/leagueRewards";
import levelUpPrices from "./modules/levelUpPrices";
import login from "./modules/login";
import professions from "./modules/professions";
import quests from "./modules/quests";
import races from "./modules/races";
import rares from "./modules/rares";
import skills from "./modules/skills";
import transactions from "./modules/transactions";
import units from "./modules/units";
import users from "./modules/users";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isLoaderVisible: false,
  },

  mutations: {
    SET_SHOW_LOADER(state, flag) {
      state.isLoaderVisible = flag;
    }
  },

  actions: {},

  modules: {
    buffs,
    guild,
    items,
    leagueRewards,
    levelUpPrices,
    login,
    professions,
    quests,
    races,
    rares,
    skills,
    transactions,
    units,
    users,
  },
})
