<template>
  <div class="empty">
    <notifications />
    <router-view />
    <div class="empty-footer">
      <div class="empty-copyrigth">
        {{ new Date().getFullYear() }} г,
        <a href="https://appfox.ru" target="_blank">Appfox</a>, все права
        защищены
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "EmptyLayout",
};
</script>

<style lang="scss">
.empty {
  background: white;
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  .empty-footer {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 82px;
    padding-left: 50px;
    padding-right: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: 1px solid #e7e7e7;

    .empty-copyrigth {
      font-size: 14px;
      color: #495057;
    }
  }
}
</style>
