<template>
  <ValidationObserver ref="loginForm" :slim="true">
    <form class="login" @submit.prevent="login()">
      <ValidationProvider
        v-slot="{ errors, reset }"
        mode="passive"
        rules="required"
        :slim="true"
      >
        <md-field :class="[{ error: errors.length }]">
          <ValidationError :errors="errors" />
          <label>Логин</label>
          <md-input
            v-model="userNameOrEmail"
            :disabled="showSpinner"
            placeholder="E-mail или никнейм"
            @focus="reset"
          />
        </md-field>
      </ValidationProvider>

      <ValidationProvider
        v-slot="{ errors, reset }"
        mode="passive"
        rules="required"
        :slim="true"
      >
        <md-field
          :class="[{ error: errors.length }]"
          :md-toggle-password="true"
        >
          <ValidationError :errors="errors" />
          <label>Пароль</label>
          <md-input
            v-model="password"
            :disabled="showSpinner"
            placeholder="********"
            type="password"
            @focus="reset"
          />
        </md-field>
      </ValidationProvider>

      <div class="btn-wrap">
        <md-button
          type="submit"
          class="md-primary md-raised login-btn"
          :disabled="showSpinner"
        >
          Войти
        </md-button>

        <div class="spin">
          <md-progress-spinner
            v-if="showSpinner"
            class="md-green"
            :md-diameter="15"
            md-mode="indeterminate"
            :md-stroke="3"
          />
        </div>
      </div>
    </form>
  </ValidationObserver>
</template>

<script>
import { mapActions } from "vuex";

export default {
  data() {
    return {
      password: "",
      showSpinner: false,
      userNameOrEmail: "",
    };
  },

  methods: {
    ...mapActions("login", ["signIn"]),

    async login() {
      const isValid = await this.$refs.loginForm.validate();
      if (!isValid) {
        return;
      }

      this.showSpinner = true;
      try {
        await this.signIn({
          userNameOrEmail: this.userNameOrEmail,
          password: this.password,
        });
        this.$router.push("/users");
      } catch (error) {
        console.error(error);
      }
      this.showSpinner = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.login {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 392px;
  width: 100%;

  &-btn {
    width: 100%;
    margin: 0;
  }

  .btn-wrap {
    position: relative;
    width: 100%;

    .spin {
      position: absolute;
      left: 20px;
      top: 50%;
      transform: translateY(-50%);
      width: 15px;
      height: 15px;
    }
  }
}
</style>
