import Vue from "vue";
import ValidationError from "@/components/Ui/ValidationError";

import {
  extend,
  ValidationObserver,
  ValidationProvider
} from "vee-validate";

import {
  // confirmed,
  // email,
  // length,
  // max,
  // min,
  required,
} from "vee-validate/dist/rules";

// extend("confirmed", {
//   ...confirmed,
//   message: "Введенные пароли не совпадают"
// });

// extend("email", {
//   ...email,
//   message: "Введите корректный {_field_}"
// });

// extend("length", {
//   ...length,
//   message: "This field is alpha"
// });

// extend("max", {
//   ...max,
//   message: `{_field_} не может быть длинее {length} символов`
// });

// extend("min", {
//   ...min,
//   message: `{_field_} не может быть короче {length} символов`
// });

extend("required", {
  ...required,
  message: "Заполните поле"
});

Vue.component("ValidationObserver", ValidationObserver);
Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationError", ValidationError);