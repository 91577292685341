export default function auth({
  next,
  store
}) {
  if (!store.state.login.token) {
    return next({
      name: "Login"
    })
  }
  return next()
}