export default {
  state: {
    currentPage: 1,
    filterParams: {
      dateBegin: "",
      dateEnd: "",
      desc: false,
      orderBy: 1,
      skip: 0,
      take: 10,
      userName: "",
    },
    sortFields: {
      "id": 1,
      "username": 2,
      "numberOfMatches": 3
    },
    totalPages: 0,
    users: [],
    userTransactions: [],
  },

  mutations: {
    INIT_PARAMS(state) {
      state.currentPage = 1;
      state.filterParams = {
        dateBegin: "",
        dateEnd: "",
        desc: false,
        orderBy: 1,
        skip: 0,
        take: 10,
        userName: "",
      }
    },

    SET_CURRENT_PAGE(state, page) {
      state.currentPage = page;
    },

    SET_DATE_BEGIN(state, date) {
      state.filterParams.dateBegin = date || "";
    },

    SET_DATE_END(state, date) {
      state.filterParams.dateEnd = date || "";
    },

    SET_ORDER_BY(state, sortBy) {
      state.filterParams.orderBy = state.sortFields[sortBy];
    },

    SET_ORDER_DIRECTION(state, sortOrder) {
      state.filterParams.desc = sortOrder == 'desc';
    },

    SET_SKIP(state, skip) {
      state.filterParams.skip = skip;
    },

    SET_USER_NAME(state, userName) {
      state.filterParams.userName = userName;
    },

    SET_USERS(state, payload) {
      state.users = payload.users;
      state.totalPages = Math.ceil(payload.numberOfUsers / state.filterParams.take);
    },

    SET_USER_TRANSACTIONS(state, transactions) {
      state.userTransactions = transactions;
    },
  },

  actions: {
    async getUserResources(_, userId) {
      try {
        // eslint-disable-next-line no-undef
        const response = await axios.post('/adminpanel/getuserresources', {
          userId
        });
        return response.data.resources;
      } catch (error) {
        console.error(error);
        this.$notifyError(error);
      }
    },

    async getUsers({
      commit,
      state
    }) {
      try {
        // eslint-disable-next-line no-undef
        const response = await axios.post('/adminpanel/getusers', state.filterParams);
        commit("SET_USERS", response.data);
      } catch (error) {
        console.error(error);
        this.$notifyError(error);
      }
    },

    async getUserTransactions({
      commit
    }, userId) {
      try {
        /* eslint-disable-next-line no-undef */
        const response = await axios.post("/adminpanel/getusertransactions", {
          userId
        });

        commit("SET_USER_TRANSACTIONS", response.data.transactions);
      } catch (error) {
        console.error(error);

        if (error.response.status !== 404) {
          this.$notifyError(error);
        }
      }
    },

    async setUserPremium(_, payload) {
      try {
        // eslint-disable-next-line no-undef
        await axios.post('/adminpanel/setuserpremium', payload);
        this.$notifySuccess("Статус премиума изменен");
      } catch (error) {
        console.error(error);
        this.$notifyError(error);
      }
    },

    async setUserResource(_, payload) {
      try {
        // eslint-disable-next-line no-undef
        await axios.post('/adminpanel/setuserresource', payload);
        this.$notifySuccess("Кол-во ресурсов изменено");
      } catch (error) {
        console.error(error);
        this.$notifyError(error);
      }
    },
  },

  getters: {
    dateBegin: state => state.filterParams.dateBegin,
    dateEnd: state => state.filterParams.dateEnd,
    limit: state => state.filterParams.take,
    userName: state => state.filterParams.userName,
    usersById: state => state.users.reduce((users, user) => {
      users[user.id] = user;
      return users;
    }, {})
  },

  namespaced: true
}
