<template>
  <div id="app">
    <component :is="layout">
      <router-view />
    </component>

    <Loader v-if="isLoaderVisible" />
  </div>
</template>

<script>
import EmptyLayout from "@/pages/Layout/Empty.vue";
import Loader from "@/components/Ui/Loader";
import MainLayout from "@/pages/Layout/Main.vue";

import { mapActions, mapMutations, mapState } from "vuex";

export default {
  components: {
    EmptyLayout,
    Loader,
    MainLayout,
  },

  computed: {
    ...mapState(["isLoaderVisible"]),

    layout() {
      return (this.$route.meta.layout || "empty") + "-layout";
    },
  },

  created() {
    this.axios.interceptors.response.use(
      undefined,
      (error) =>
        new Promise(() => {
          const { status } = error.response;

          if (status == 401) {
            this.logout();
            this.$router.push("/login");
            this.showLoader(false);
          }

          throw error;
        })
    );
  },

  mounted() {
    if (localStorage.getItem("dreamLeadToken")) {
      let token = localStorage.getItem("dreamLeadToken");
      this.login(token);
      this.$router.push("/");
    }
  },

  methods: {
    ...mapActions("login", ["logout"]),

    ...mapMutations({
      login: "login/LOGGED_IN",
      showLoader: "SET_SHOW_LOADER",
    }),
  },
};
</script>
