export default {
  state: {
    token: null,
  },

  mutations: {
    LOGGED_IN(state, token) {
      localStorage.setItem("dreamLeadToken", token);
      state.token = token;

      /* eslint-disable-next-line no-undef */
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    },
  },

  actions: {
    async signIn({
      commit
    }, payload) {
      try {
        /* eslint-disable-next-line no-undef */
        const response = await axios.post("/adminpanel/login", {
          userNameOrEmail: payload.userNameOrEmail,
          password: payload.password
        });

        commit("LOGGED_IN", response.data.token);
        this.$notifySuccess("Вход выполнен");

        return true;
      } catch (error) {
        console.error(error);
        this.$notifyError(error);
      }
    },

    logout({
      commit
    }) {
      commit("LOGGED_IN", null);
    }
  },

  getters: {},

  namespaced: true
}
