<template>
  <div class="md-layout">
    <router-view />
  </div>
</template>

<script>
import { mapMutations } from "vuex";

export default {
  beforeRouteLeave(to, from, next) {
    this.initParams();
    next();
  },

  methods: {
    ...mapMutations({
      initParams: "users/INIT_PARAMS",
    }),
  },
};
</script>

<style lang="scss" scoped>
.md-layout {
  position: relative;
  z-index: 2;
}
</style>
