export default {
  state: {
    rares: [],
  },

  mutations: {
    SET_RARES(state, rares) {
      state.rares = rares;
    },
  },

  actions: {
    async getRares({
      commit
    }) {
      try {
        // eslint-disable-next-line no-undef
        const response = await axios.get('/adminpanel/getcharacterrares');
        commit("SET_RARES", response.data.rares);
      } catch (error) {
        console.error(error);
        this.$notifyError(error);
      }
    },
  },

  getters: {
    raresById: (state) => state.rares.reduce((rares, rare) => {
      rares[rare.id] = rare.name;
      return rares;
    }, {}),
  },

  namespaced: true
}
