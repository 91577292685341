export default {
  state: {
    skills: [],
    skillLevels: [],
  },

  mutations: {
    SET_SKILLS(state, skills) {
      state.skills = skills;
    },

    SET_SKILL_LEVELS(state, levels) {
      state.skillLevels = levels;
    }
  },

  actions: {
    async getSkills({
      commit
    }) {
      try {
        // eslint-disable-next-line no-undef
        const response = await axios.get('/adminpanel/getskills');
        commit("SET_SKILLS", response.data.skills);
      } catch (error) {
        console.error(error);
        this.$notifyError(error);
      }
    },

    async getSkillLevels({
      commit
    }, skillId) {
      try {
        // eslint-disable-next-line no-undef
        const response = await axios.post('/adminpanel/getskilllevels', {
          skillId
        });
        commit("SET_SKILL_LEVELS", response.data.skillLevels);
      } catch (error) {
        console.error(error);
        this.$notifyError(error);
      }
    },

    async setSkill(_, {
      skillId,
      name,
      description
    }) {
      try {
        // eslint-disable-next-line no-undef
        await axios.post('/adminpanel/setskill', {
          skillId,
          name,
          description
        });
        this.$notifySuccess("Скилл изменен")
      } catch (error) {
        console.error(error);
        this.$notifyError(error);
      }
    },
  },

  getters: {
    skillsById: (state) => state.skills.reduce((skills, skill) => {
      skills[skill.skillId] = skill;
      return skills;
    }, {}),
  },

  namespaced: true
}
