export default {
  state: {
    professions: [],
  },

  mutations: {
    SET_PROFESSIONS(state, professions) {
      state.professions = professions;
    },
  },

  actions: {
    async getProfessions({
      commit
    }) {
      try {
        // eslint-disable-next-line no-undef
        const response = await axios.get('/adminpanel/getprofessions');
        commit("SET_PROFESSIONS", response.data.professions);
      } catch (error) {
        console.error(error);
        this.$notifyError(error);
      }
    },
  },

  getters: {
    professionsById: (state) => state.professions.reduce((professions, profession) => {
      professions[profession.id] = profession.name;
      return professions;
    }, {}),
  },

  namespaced: true
}
