export default {
  state: {
    items: [],
    itemTypes: [],
    shopItems: [],
  },

  mutations: {
    SET_ITEMS(state, items) {
      state.items = items;
    },

    SET_ITEM_TYPES(state, itemTypes) {
      state.itemTypes = itemTypes;
    },

    SET_SHOP_ITEMS(state, items) {
      state.shopItems = items;
    },
  },

  actions: {
    async getItems({
      commit
    }) {
      try {
        // eslint-disable-next-line no-undef
        const response = await axios.get('/adminpanel/getitems');
        commit("SET_ITEMS", response.data.items);
      } catch (error) {
        console.error(error);
        this.$notifyError(error);
      }
    },

    async getItemTypes({
      commit
    }) {
      try {
        // eslint-disable-next-line no-undef
        const response = await axios.get('/adminpanel/getitemtypes');
        commit("SET_ITEM_TYPES", response.data.itemTypes);
      } catch (error) {
        console.error(error);
        this.$notifyError(error);
      }
    },

    async getShopItems({
      commit
    }) {
      try {
        // eslint-disable-next-line no-undef
        const response = await axios.get('/adminpanel/getshopitems');
        commit("SET_SHOP_ITEMS", response.data.shopItems);
      } catch (error) {
        console.error(error);
        this.$notifyError(error);
      }
    },

    async setItem(_, item) {
      try {
        // eslint-disable-next-line no-undef
        await axios.post('/adminpanel/setitem', item);
        this.$notifySuccess("Предмет изменен")
      } catch (error) {
        console.error(error);
        this.$notifyError(error);
      }
    },

    async setShopItem(_, item) {
      try {
        // eslint-disable-next-line no-undef
        await axios.post('/adminpanel/setshopitem', item);
        this.$notifySuccess("Предмет изменен")
      } catch (error) {
        console.error(error);
        this.$notifyError(error);
      }
    },
  },

  getters: {
    itemTypesById: (state) => state.itemTypes.reduce((itemTypes, itemType) => {
      itemTypes[itemType.id] = itemType.name;
      return itemTypes;
    }, {}),
  },

  namespaced: true
}
