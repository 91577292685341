export default {
  state: {
    currentPage: 1,
    filterParams: {
      dateBegin: "",
      dateEnd: "",
      desc: false,
      orderBy: "transactionId",
      skip: 0,
      take: 10,
      userId: null,
    },
    totalPages: 0,
    transactions: [],
  },

  mutations: {
    INIT_PARAMS(state) {
      state.currentPage = 1;
      state.filterParams = {
        dateBegin: "",
        dateEnd: "",
        desc: false,
        orderBy: "transactionId",
        skip: 0,
        take: 10,
        userId: null,
      }
    },

    SET_CURRENT_PAGE(state, page) {
      state.currentPage = page;
    },

    SET_DATE_BEGIN(state, date) {
      state.filterParams.dateBegin = date || "";
    },

    SET_DATE_END(state, date) {
      state.filterParams.dateEnd = date || "";
    },

    SET_ORDER_BY(state, sortBy) {
      state.filterParams.orderBy = sortBy;
    },

    SET_ORDER_DIRECTION(state, sortOrder) {
      state.filterParams.desc = sortOrder == 'desc';
    },

    SET_SKIP(state, skip) {
      state.filterParams.skip = skip;
    },

    SET_USER_ID(state, userId) {
      state.filterParams.userId = userId;
    },

    SET_TRANSACTIONS(state, payload) {
      state.transactions = payload.transactions;
      state.totalPages = Math.ceil(payload.memberOfTransactions / state.filterParams.take);
    },
  },

  actions: {
    async getTransactions({
      commit,
      state
    }) {
      try {
        // eslint-disable-next-line no-undef
        const response = await axios.post('/adminpanel/gettransactions', state.filterParams);
        commit("SET_TRANSACTIONS", response.data);
      } catch (error) {
        console.error(error);
        this.$notifyError(error);
      }
    },
  },

  getters: {
    dateBegin: state => state.filterParams.dateBegin,
    dateEnd: state => state.filterParams.dateEnd,
    limit: state => state.filterParams.take,
  },

  namespaced: true
}
