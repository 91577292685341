export default {
  state: {
    guildData: null,
  },

  mutations: {
    SET_GUILD_DATA(state, guildData) {
      state.guildData = guildData;
    },
  },

  actions: {
    async getGuildData({
      commit
    }) {
      try {
        // eslint-disable-next-line no-undef
        const response = await axios.get('/adminpanel/getguilddata');
        commit("SET_GUILD_DATA", response.data);
      } catch (error) {
        console.error(error);
        this.$notifyError(error);
      }
    },

    async setGuildData(_, guildData) {
      try {
        // eslint-disable-next-line no-undef
        await axios.post('/adminpanel/setguilddata', guildData);
        this.$notifySuccess("Настройки гильдии изменены")
      } catch (error) {
        console.error(error);
        this.$notifyError(error);
      }
    },
  },

  getters: {},

  namespaced: true
}
