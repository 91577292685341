export default {
  state: {
    charactersLevelUpPrices: [],
    skillLevelUpPrices: [],
  },

  mutations: {
    SET_CHARACTER_LEVEL_UP_PRICES(state, prices) {
      state.charactersLevelUpPrices = prices;
    },

    SET_SKILL_LEVEL_UP_PRICES(state, prices) {
      state.skillLevelUpPrices = prices;
    },
  },

  actions: {
    async getCharacterLevelUpPrices({
      commit
    }) {
      try {
        // eslint-disable-next-line no-undef
        const response = await axios.get('/adminpanel/getcharacterlevelupprices');
        commit("SET_CHARACTER_LEVEL_UP_PRICES", response.data.characterLevelUpPrices);
      } catch (error) {
        console.error(error);
        this.$notifyError(error);
      }
    },

    async getSkillLevelUpPrices({
      commit
    }) {
      try {
        // eslint-disable-next-line no-undef
        const response = await axios.get('/adminpanel/getskilllevelupprices');
        commit("SET_SKILL_LEVEL_UP_PRICES", response.data.skillLevelUpPrices);
      } catch (error) {
        console.error(error);
        this.$notifyError(error);
      }
    },

    async setCharacterLevelUpPrice(_, {
      characterLevelUpPriceId,
      priceGold,
      priceFragments
    }) {
      try {
        // eslint-disable-next-line no-undef
        await axios.post('/adminpanel/setcharacterlevelupprice', {
          characterLevelUpPriceId,
          priceGold,
          priceFragments
        });
        this.$notifySuccess("Стоимость изменена")
      } catch (error) {
        console.error(error);
        this.$notifyError(error);
      }
    },

    async setSkillLevelUpPrice(_, {
      skillLevelUpPriceId,
      crystal,
      book
    }) {
      try {
        // eslint-disable-next-line no-undef
        await axios.post('/adminpanel/setskilllevelupprice', {
          skillLevelUpPriceId,
          crystal,
          book
        });
        this.$notifySuccess("Стоимость изменена")
      } catch (error) {
        console.error(error);
        this.$notifyError(error);
      }
    },
  },

  getters: {},

  namespaced: true
}
