import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";


import auth from "./middleware/auth";
import guest from "./middleware/guest";
import middlewarePipeline from "./middleware/middlewarePipeline";

import Login from "@/pages/Login";
import Main from "@/pages";
import Users from "@/pages/Users";

Vue.use(VueRouter)

const routes = [{
    path: "/",
    name: "Main",
    component: Main,
    redirect: "/users",
    meta: {
      layout: "main",
      middleware: [
        auth,
      ]
    }
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: {
      layout: "empty",
      middleware: [
        guest,
      ]
    }
  },

  {
    path: "/users",
    name: "Users",
    component: Users,
    redirect: "/users/list",
    meta: {
      layout: "main",
      middleware: [
        auth,
      ],
    },
    children: [{
        path: "/users/list",
        name: "Users List",
        meta: {
          layout: "main",
          middleware: [
            auth,
          ],
        },
        component: () => import(`@/pages/Users/List`)
      },
      {
        path: "/users/:id",
        name: "User Info",
        component: () => import(`@/pages/Users/User/index.vue`),
        redirect: "/users/:id/transactions",
        meta: {
          layout: "main",
          middleware: [
            auth,
          ],
        },
        children: [{
            path: "/users/:id/transactions",
            name: "User Transactions",
            meta: {
              layout: "main",
              middleware: [
                auth,
              ],
            },
            component: () => import(`@/pages/Users/User/Transactions`)
          },
          {
            path: "/users/:id/resources",
            name: "User Resources",
            meta: {
              layout: "main",
              middleware: [
                auth,
              ],
            },
            component: () => import(`@/pages/Users/User/Resources`)
          },
        ]
      },
    ]
  },

  {
    path: "/transactions",
    name: "Transactions",
    component: () => import('@/pages/Transactions'),
    redirect: "/transactions/list",
    meta: {
      layout: "main",
      middleware: [
        auth,
      ],
    },
    children: [{
      path: "/transactions/list",
      name: "Transactions List",
      meta: {
        layout: "main",
        middleware: [
          auth,
        ],
      },
      component: () => import(`@/pages/Transactions/List`)
    }, ]
  },

  {
    path: "/level-up-prices",
    name: "LevelUpPrices",
    component: () => import(`@/pages/LevelUpPrices`),
    redirect: "/level-up-prices/characters",
    meta: {
      layout: "main",
      middleware: [
        auth,
      ],
    },
    children: [{
        path: "/level-up-prices/characters",
        name: "CharacterLevelUpPrices",
        meta: {
          layout: "main",
          middleware: [
            auth,
          ],
        },
        component: () => import(`@/pages/LevelUpPrices/Characters`)
      },
      {
        path: "/level-up-prices/skill",
        name: "SkillLevelUpPrices",
        meta: {
          layout: "main",
          middleware: [
            auth,
          ],
        },
        component: () => import(`@/pages/LevelUpPrices/Skill`)
      },
    ]
  },

  {
    path: "/units",
    name: "Units",
    component: () => import('@/pages/Units'),
    redirect: "/units/list",
    meta: {
      layout: "main",
      middleware: [
        auth,
      ],
    },
    children: [{
        path: "/units/list",
        name: "Units List",
        meta: {
          layout: "main",
          middleware: [
            auth,
          ],
        },
        component: () => import(`@/pages/Units/List`)
      },
      {
        path: "/units/:id",
        name: "Unit",
        meta: {
          layout: "main",
          middleware: [
            auth,
          ],
        },
        component: () => import(`@/pages/Units/Unit`)
      },
    ]
  },

  {
    path: "/league-rewards",
    name: "League Rewards",
    component: () => import('@/pages/LeagueRewards'),
    meta: {
      layout: "main",
      middleware: [
        auth,
      ],
    },
  },

  {
    path: "/guild-settings",
    name: "Guild Settings",
    component: () => import('@/pages/GuildSettings'),
    meta: {
      layout: "main",
      middleware: [
        auth,
      ],
    },
  },

  {
    path: "/quests",
    name: "Quests",
    component: () => import('@/pages/Quests'),
    meta: {
      layout: "main",
      middleware: [
        auth,
      ],
    },
  },

  {
    path: "/skills",
    name: "Skills",
    component: () => import(`@/pages/Skills`),
    redirect: "/skills/list",
    meta: {
      layout: "main",
      middleware: [
        auth,
      ],
    },
    children: [{
        path: "/skills/list",
        name: "Skills List",
        meta: {
          layout: "main",
          middleware: [
            auth,
          ],
        },
        component: () => import(`@/pages/Skills/SkillsList`)
      },

      {
        path: "/skills/buffs",
        name: "Buffs",
        meta: {
          layout: "main",
          middleware: [
            auth,
          ],
        },
        component: () => import(`@/pages/Skills/BuffsList`)
      },
      {
        path: "/skills/:id",
        name: "Skill Edit",
        meta: {
          layout: "main",
          middleware: [
            auth,
          ],
        },
        component: () => import(`@/pages/Skills/Skill`)
      },
    ]
  },

  {
    path: "/items",
    name: "Items",
    component: () => import('@/pages/Items'),
    redirect: "/items/list",
    meta: {
      layout: "main",
      middleware: [
        auth,
      ],
    },
    children: [{
        path: "/items/list",
        name: "Items List",
        meta: {
          layout: "main",
          middleware: [
            auth,
          ],
        },
        component: () => import(`@/pages/Items/List`)
      },
      {
        path: "/items/shop",
        name: "Shop Items",
        meta: {
          layout: "main",
          middleware: [
            auth,
          ],
        },
        component: () => import(`@/pages/Items/Shop`)
      },
    ]
  },
]

const router = new VueRouter({
  mode: "history",
  routes,
  linkExactActiveClass: "nav-item active",
  linkActiveClass: "nav-item active"
})

router.beforeEach((to, from, next) => {
  if (!to.meta.middleware) {
    return next();
  }

  const middleware = to.meta.middleware;
  const context = {
    to,
    from,
    next,
    store
  };

  return middleware[0]({
    ...context,
    next: middlewarePipeline(context, middleware, 1)
  })
})

export default router
