export default {
  state: {
    complexities: [],
    intensities: [],
    quests: [],
    types: [],
  },

  mutations: {
    SET_QUESTS_DATA(state, questsData) {
      state.complexities = questsData.complexities;
      state.intensities = questsData.intensities;
      state.quests = questsData.quests;
      state.types = questsData.types;
    },
  },

  actions: {
    async getQuestsData({
      commit
    }) {
      try {
        // eslint-disable-next-line no-undef
        const response = await axios.get('/adminpanel/getquests');
        commit("SET_QUESTS_DATA", response.data);
      } catch (error) {
        console.error(error);
        this.$notifyError(error);
      }
    },

    async setQuest(_, quest) {
      try {
        // eslint-disable-next-line no-undef
        await axios.post('/adminpanel/setquest', quest);
        this.$notifySuccess("Настройки квеста изменены")
      } catch (error) {
        console.error(error);
        this.$notifyError(error);
      }
    },
  },

  getters: {
    compexitiesById: (state) => state.complexities.reduce((complexities, complexity) => {
      complexities[complexity.id] = complexity.name;
      return complexities;
    }, {}),

    intensitiesById: (state) => state.intensities.reduce((intensities, intensity) => {
      intensities[intensity.id] = intensity.name;
      return intensities;
    }, {}),

    typesById: (state) => state.types.reduce((types, type) => {
      types[type.id] = type.name;
      return types;
    }, {}),
  },

  namespaced: true
}
