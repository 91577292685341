export default {
  state: {
    races: [],
  },

  mutations: {
    SET_RACES(state, races) {
      state.races = races;
    },
  },

  actions: {
    async getRaces({
      commit
    }) {
      try {
        // eslint-disable-next-line no-undef
        const response = await axios.get('/adminpanel/getcharacterraces');
        commit("SET_RACES", response.data.races);
      } catch (error) {
        console.error(error);
        this.$notifyError(error);
      }
    },
  },

  getters: {
    racesById: (state) => state.races.reduce((races, race) => {
      races[race.id] = race.name;
      return races;
    }, {}),
  },

  namespaced: true
}
