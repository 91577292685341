export default {
  state: {
    leagueRewards: [],
  },

  mutations: {
    SET_LEAGUE_REWARDS(state, rewards) {
      state.leagueRewards = rewards;
    },
  },

  actions: {
    async getLeagueRewards({
      commit
    }) {
      try {
        // eslint-disable-next-line no-undef
        const response = await axios.get('/adminpanel/getleaguerewards');
        commit("SET_LEAGUE_REWARDS", response.data.leagueRewards);
      } catch (error) {
        console.error(error);
        this.$notifyError(error);
      }
    },

    async setLeagueReward(_, reward) {
      try {
        // eslint-disable-next-line no-undef
        await axios.post('/adminpanel/setleaguereward', reward);
        this.$notifySuccess("Награда изменена")
      } catch (error) {
        console.error(error);
        this.$notifyError(error);
      }
    },
  },

  getters: {},

  namespaced: true
}
