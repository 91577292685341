<template>
  <div class="wrapper" :class="{ 'nav-open': $sidebar.showSidebar }">
    <notifications />

    <SideBar
      :sidebar-item-color="sidebarBackground"
      :sidebar-background-image="sidebarBackgroundImage"
    >
      <SidebarLink
        v-for="(link, index) in links"
        :key="`link_${index}`"
        :to="link.path"
        :exact="link.path == '/'"
      >
        <p>{{ link.title }}</p>
      </SidebarLink>
    </SideBar>

    <div class="main-panel">
      <div class="top-line" />
      <TopNavbar />

      <DashboardContent />
    </div>
  </div>
</template>

<script>
import TopNavbar from "./TopNavbar.vue";
import DashboardContent from "./Content.vue";

export default {
  components: {
    TopNavbar,
    DashboardContent,
  },

  data() {
    return {
      sidebarBackground: "green",
      sidebarBackgroundImage: require("@/assets/img/sidebar-2.jpg"),
      links: [
        {
          path: "/users",
          title: "Пользователи",
        },
        {
          path: "/transactions",
          title: "Транзакции",
        },
        {
          path: "/level-up-prices",
          title: "Стоимость прокачки",
        },
        {
          path: "/units",
          title: "Статы юнитов",
        },
        {
          path: "/league-rewards",
          title: "Награды лиги",
        },

        {
          path: "/guild-settings",
          title: "Настройки гильдии",
        },

        {
          path: "/quests",
          title: "Квесты",
        },

        {
          path: "/skills",
          title: "Скиллы",
        },

        {
          path: "/items",
          title: "Предметы",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.counter {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 16px;
}

.top-line {
  background-color: red;
  height: 20px;
}
</style>