export default {
  state: {
    selectedUnit: null,
    units: [],
  },

  mutations: {
    SET_SELECTED_UNIT(state, unit) {
      state.selectedUnit = unit;
    },

    SET_UNITS(state, units) {
      let unitsArray = [];
      units.forEach((unit) => {
        unit.attribute.forEach((attribute) => {
          unitsArray.push({
            characterId: unit.characterId,
            name: unit.name,
            characterRaceId: unit.characterRaceId,
            characterRareId: unit.characterRareId,
            maxLevel: unit.maxLevel,
            attribute
          });
        })
      });

      state.units = unitsArray;
    },
  },

  actions: {
    async getUnits({
      commit
    }) {
      try {
        // eslint-disable-next-line no-undef
        const response = await axios.get('/adminpanel/getcharacters');
        commit("SET_UNITS", response.data.characters);
      } catch (error) {
        console.error(error);
        this.$notifyError(error);
      }
    },

    async setUnitAttributes(_, attributes) {
      try {
        // eslint-disable-next-line no-undef
        await axios.post('/adminpanel/setcharacterattribute', attributes);
        this.$notifySuccess("Статы изменены")
      } catch (error) {
        console.error(error);
        this.$notifyError(error);
      }
    },
  },

  getters: {},

  namespaced: true
}
